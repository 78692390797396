const INPUT_RULES = {
  required: [(v) => !!v || ""],
  email: [(v) => !!v || "", (v) => /.+@.+\..+/.test(v) || "invalid email"],
  phone: [
    (v) => !!v || "",
    (v) => (v && v.length == 11) || "invalid phone number",
  ],
  grade: [(v) => (v <= 100 && v >= 0) || ""],
  number: [(v) => !!v || "", (v) => v > 0 || "Integer required"],
  year: [
    (v) => !!v || "",
    (v) => (v >= 1970 && v < 3000) || "Invalid year, must be later than 1970",
  ],
};

const FILE_TYPES = {
  doc: "application/pdf",
  image: "image/png,image/jpeg,image/gif,image/bmp",
};

const APPLICATION_FIELDS = {
  full_name: "Full Name(Name on Passport/ID)",
  name_cn: "Chinese Name",
  native_name: "Name in Native Language",
  position_type: "Position Type",
  nationality: "Nationality",
  birthday: "Date of Birth",
  timezone: "Time Zone(For Online Interview)",
  current_institute: "Current Institute",
  current_position: "Position & Fellowship in Institute",
  current_address: "Current Address",
  phd_institute: "PhD institute",
  phd_date: "PhD date",
  ads_link: "Link to Personal ADS Publication Library",
  material: "Application Material",
};

const BASIC_FIELDS = [
  "full_name",
  "name_cn",
  "native_name",
  "position_type",
  "nationality",
  "birthday",
  "timezone",
  "current_institute",
  "current_position",
  "current_address",
  "phd_institute",
  "phd_date",
];

const RESEARCH_FIELDS = ["ads_link", "research"];

const POSITION_TYPES = ["Tenure-track", "Tenured"];

export default {
  INPUT_RULES,
  FILE_TYPES,
  APPLICATION_FIELDS,
  BASIC_FIELDS,
  RESEARCH_FIELDS,
  POSITION_TYPES,
};
